<template>
    <div class="bid_wrap license_wrap vote_detail item_detail_open_wrap competition_default_wrap">
        <div class="bid_main_wrap">
            <div class="bid_content_wrap">
                <div class="bid_title_wrap">
                    <h1 class="title">{{ $t('competition') }}</h1>
                    <div class="status_change_wrap">
                        <div class="item_status_btn" @click="moveUpdate(`update`)"
                             v-show="(UserInfo.mb_no  === competitionData.mb_no) && (competitionData.c_status === 0)">
                            {{ $t('modify') }}
                        </div>
                        <div class="item_status_btn" @click="deleteConfirm()"
                             v-show="(UserInfo.mb_no  === competitionData.mb_no) && (competitionData.c_status === 0)">
                            {{ $t('c_comp') }}
                        </div>
                    </div>
                </div>

                <div class="content_wrap">

                    <div class="img_wrap">
                        <div class="b_img">
                            <img :src="setFitWidthImg(competitionData.Poster,730)"
                                 @click="openImageFile(competitionData.Poster)"
                                 style="cursor: pointer"/>
                        </div>
                    </div>
                    <div class="inform_wrap">
                        <div class="inform_header">
                            <div class="i_header_top">
                                <div class="share_btn" @click="openShareModal(competitionData)" style="width: 41px;height: 41px;"></div>
                                <div class="right">
                                    <like-competition-component :like-type="`competitionDetail`"
                                                                :like-data="competitionData"></like-competition-component>
                                    <div class="icon_wrap">
                                        <img class="icon eye" src="@/assets/image/main/eye1.png" alt="">
                                        <div class="icon_count">{{ returnCommas(competitionData.c_hit) }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="inform_h_header">
                                <div class="time_remain_box">
                                    <div class="date_wrap">
                                        <competition-date-time
                                                :competition-data="competitionData"></competition-date-time>
                                    </div>
                                    <div class="pdf_wrap">
                                        <span class="pdf_content"
                                              @click="awsS3Download('contestIntro',competitionData.Intro[0],competitionData.c_idx)"><i></i>{{
                                                $t('download_file')
                                            }}</span>
                                    </div>
                                </div>
                                <ul class="thumb_img">
                                    <li>
                                        <!--                    <img :src="returnImage(competitionData.Profile,45,45)">
                                                            <p>{{competitionData.mb_nick}}</p>-->
                                        <el-popover
                                                :open-delay="500"
                                                :offset="0"
                                                @show="getMemberData(competitionData.mb_no)"
                                                :append-to-body="false"
                                                :popper-options="{
                                              boundariesElement: 'body',
                                              gpuAcceleration: true,
                                              positionFixed: true,
                                              preventOverflow: true,
                                            }"
                                                trigger="click"
                                                popper-class="bid_popover">
                                            <img class="rad_img" slot="reference"
                                                 :src="returnImage(competitionData.Profile,45,45, 'png')">
                                            <p slot="reference">{{ competitionData.mb_nick }}</p>
                                            <div class="popover_wrap" v-if="checkMemberData()"
                                                 @click="movePage(`/creator/${memberData.mb_uuid}`)">
                                                <div class="popover_top">
                                                    <div class="popover_creator">
                                                        {{ $t('organizer') }}
                                                    </div>
                                                    <div class="popover_nickname">
                                                        <img class="nickname_img" :src="returnMemberImg()">
                                                        <div class="popover_text_list">
                                                            <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                                <span>Curator</span></div>
                                                            <div class="popover_nickname_list">
                                                                <div class="nick_text">{{ memberData.mb_nick }}</div>

                                                                <div class="pop_curator second_color"
                                                                     v-if="memberData.mb_premium===1">
                                                                    <span>Special</span></div>
                                                            </div>
                                                            <div class="popover_nickname2">
                                                                <img class="pn_img"
                                                                     src="@/assets/image/main/heart1.png">
                                                                <div>{{ memberData.likeCnt }}</div>
                                                                <div>ITEM</div>
                                                                <div>{{ memberData.itemCnt }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="popover_bottom">
                                                    <div>
                                                        {{ memberData.mb_introduce }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="popover_wrap" v-else>
                                                <div class="popover_top no_profile">
                                                    <div class="popover_creator no_profile_creator">
                                                        {{ $t('k_creator') }}
                                                    </div>
                                                    <div class="popover_nickname no_profile_nickname">
                                                        <img class="nickname_img no_profile_img"
                                                             src="@/assets/image/main/no_profile.png">
                                                        <div class="popover_text_list no_profile_text">
                                                            <div class="popover_nickname_list">
                                                                <div class="nick_text no_profile_text">{{
                                                                        $t('notInfo')
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="popover_bottom no_profile_bottom">
                                                    <div class="pop_pover_div no_profile_div">
                                                        {{ $t('notProvider') }}
                                                    </div>
                                                </div>
                                            </div>
                                        </el-popover>
                                    </li>
                                </ul>
                            </div>
                            <div class="header_title">
                                <h3 class="title_left">{{ returnCategoryName(competitionData.cc_idx) }}</h3>
                                <h3 class="title_right">{{ competitionData.c_title }}</h3>
                            </div>
                        </div>
                        <div class="inform_content">
                            <div class="hashtag_wrap">
                                <ul>
                                    <li v-for="(hash,index) in returnHashtag(competitionData.Hashtag)"
                                        :key="`hash-${index}`">
                                        <span>#{{ hash }}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="content_mid">
                                <ul>
                                    <li>
                                        <h3 class="sub_title">{{ $t('info') }}</h3>
                                        <ul>
                                            <li>
                                                <h3>{{ $t('c_ju') }}</h3>
                                                <p class="ql-editor" v-html="replaceBr(competitionData.c_organize)"></p>
                                            </li>
                                            <li>
                                                <h3>{{ $t('target') }}</h3>
                                                <p class="ql-editor" v-html="replaceBr(competitionData.c_target)"></p>
                                            </li>
                                            <li>
                                                <h3>{{ $t('entry') }}</h3>
                                                <p>{{ returnApplyDate(competitionData) }}</p>
                                            </li>
                                            <li>
                                                <h3>{{ $t('presentation') }}</h3>
                                                <p>{{ returnReleaseDate(competitionData.c_release_time) }}</p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h3 class="sub_title sub_left">{{ $t('t_prize') }}</h3>
                                        <div class="sub_right">
                                            <span class="sub_span_mg">{{
                                                    returnCommas(competitionData.c_prize)
                                                }} Mg</span><br/>
                                            <span class="sub_span_w">({{
                                                    returnMingle(competitionData.c_prize)
                                                }})</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="btn_wrap" v-if="competitionData.c_status === 3 ">
                                <!--                                <button class="gobid goaward" @click="movePage(`/award/${competitionData.c_idx}/list`)">{{ $t('go_award') }}</button>-->
                                <button class="gobid goaward"
                                        @click="movePage(`/award/${competitionData.c_uuid}/list`)">{{ $t('go_award') }}
                                </button>
                            </div>
                            <div class="btn_wrap" v-else>
                                <!--                                <button class="gobid"
                                                                        @click="movePage(`/entry/${competitionData.c_idx}/list/${competitionData.mb_no}`)"
                                                                        v-if="competitionData.c_status !== 0">{{ $t('s_apply') }}</button>-->
                                <button class="gobid"
                                        @click="movePage(`/entry/${competitionData.c_uuid}/list/${competitionData.mb_uuid}`)"
                                        v-if="competitionData.c_status !== 0">{{ $t('s_apply') }}
                                </button>
                                <button class="gobid" @click="checkApply()"
                                        v-show="UserInfo.mb_no !== competitionData.mb_no"
                                        v-if="checkJudges(competitionData)">{{ $t('apply') }}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="tab_detail_wrap">

                    <div class="item_tab">
                        <ul class="tab_wrap">
                            <li @click="changeTab('item')" :class="{ active : tab === 'item' }" class="item_tab1">
                                <div class="tab">{{ $t('comp_content') }}</div>
                            </li>
                            <li @click="changeTab('block')" :class="{ active : tab === 'block' }"
                                class="blockchain_tab1">
                                <div class="tab">{{ $t('information') }}</div>
                            </li>
                            <li @click="changeTab('history')" :class="{ active : tab === 'history' }" class="nft_tab1">
                                <div class="tab">{{ $t('standard') }}</div>
                            </li>
                        </ul>
                        <div class="tab_inner_content" v-if=" tab === 'item'">
                            <h3>{{ $t('comp_con') }}</h3>
                            <div v-html="replaceBr(competitionData.c_context)"></div>
                        </div>
                    </div>

                    <div class="block_tab" v-if=" tab === 'block'">
                        <div class="tab_inner_content">
                            <h3>{{ $t('inform_license') }}</h3>
                            <div v-html="replaceBr(competitionData.c_license)"></div>
                        </div>
                    </div>

                    <div class="history_tab" v-if=" tab === 'history'">
                        <div class="tab_inner_content">
                            <h3>{{ $t('standard') }}</h3>
                            <ul>
                                <li v-for=" (evaluate,index) in returnEvaluates(competitionData.Evaluates)"
                                    :key="`evaluate-${index}`">
                                    <p> {{ evaluate }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="hasData(competitionData)">
                        <competition-comment-component :competitionType="`comment`"
                                                       :competitionKey="competitionData.c_idx"
                                                       :competitionData="competitionData">
                        </competition-comment-component>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import CompetitionDateTime from "@/components/competition/CompetitionDateTime";
import CompetitionCategoryMixin from "@/mixins/CompetitionCategoryMixin";
import LikeComponent from "@/components/like/LikeComponent";
import LikeCompetitionComponent from "@/components/like/LikeCompetitionComponent";
import {mapState} from "vuex";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import fileMixins from "@/mixins/fileMixins";
import AuthMixins from "@/mixins/AuthMixins";
import awsS3FileDownload from "@/mixins/awsS3FileDownload";
import priceMixins from "@/mixins/priceMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import CompetitionCommentComponent from "@/components/competition/comment/CompetitionCommentComponent";

export default {
    name: "CompetitionDetailDefaultLayout",
    mixins: [imageOption, imageResizeMixins, CompetitionCategoryMixin, alertMixins, fileMixins, AuthMixins, awsS3FileDownload, priceMixins],
    components: {
        CompetitionCommentComponent, LikeCompetitionComponent, LikeComponent, CompetitionDateTime
    },
    inject: ['deleteCompetition'],
    provide() {
        return {}
    },
    props: {
        competitionData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            tab: 'item',
            memberData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.tab = this.$route.query.tab;
        if (util.isEmpty(this.tab)) {
            this.changeTab('item');
        }
        this.setMetaTitle();
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        setMetaTitle() {
            if (!util.isEmpty(this.competitionData) && this.competitionData.hasOwnProperty('mb_nick')) {
                EventBus.$emit('setMetaTitle', this.competitionData.c_title, this.competitionData.mb_nick);
            } else if (!util.isEmpty(this.competitionData)) {
                EventBus.$emit('setMetaTitle', this.competitionData.c_title);
            }
        },
        hasData(data) {
            return !util.isEmpty(data);
        },
        movePage(url) {
            this.$router.push(url);
        },
        moveUpdate(url) {
            this.$router.push(`${this.$route.path}/${url}`);
        },
        changeTab(tab) {
            this.tab = ''
            this.$nextTick(() => {
                this.tab = tab;
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
        returnImage(image, width = 32, height = 32, f = 'jpg') {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, f)}`
                // return `${image[0].org_url}`
            }
            return ''
        },
        returnOrgImage(image) {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}`
            }
            return ''
        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        returnCommas(x = 0) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        returnHashtag(hashtag) {
            let list = []
            util.each(hashtag, (hash, index, arr) => {
                if (!util.isEmpty(hash.ic_name) && hash.ch_type === 1) {
                    list.push(hash.ic_name)
                }
                if (!util.isEmpty(hash.ch_hashtag) && hash.ch_type === 2) {
                    list.push(hash.ch_hashtag)
                }
            })
            return list
        },
        returnApplyDate(data) {
            let s_time = this.$moment(data.c_start_time).format('YYYY.MM.DD HH:mm')
            let e_time = this.$moment(data.c_end_time).format('YYYY.MM.DD HH:mm')
            return `${s_time} ~ ${e_time}`
        },
        returnReleaseDate(time) {
            let r_time = this.$moment(time).format('YYYY.MM.DD HH:mm')
            return r_time;
        },
        returnEvaluates(evaluates = []) {
            let list = []
            util.each(evaluates, (evaluate, index, arr) => {
                list.push(evaluate.cs_evaluate)
            })
            return list
        },
        returnPoster(poster) {
            return !util.isEmpty(poster) ? poster[0].org_url : ''
        },
        returnIntroName(intro) {
            return !util.isEmpty(intro) ? intro[0].org_name : ''
        },
        deleteConfirm() {
            let key = this.competitionData.c_idx
            this.createConfirm({
                content: this.$t('competition_cancel_msg'),
                confirm: () => {
                    this.deleteCompetition(key)
                },
                cancel: () => {

                }
            })
        },
        checkApply() {
            if (!this.isLogin()) {
                this.createConfirm({
                    content: this.$t('not_login_confirm'),
                    confirmText: this.$t('go_login'),
                    confirm: () => {
                        this.$router.push('/auth');
                    }
                })
                return false;
            } else {
                EventBus.$emit('checkApply')
            }
        },
        getMemberData(mb_no) {
            let params = {
                no: mb_no
            }
            this.$api.$member.getProfile(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type != 1 || util.isEmpty(res.List)) {
                    this.createAlert({
                        content: this.$t('detect_detail_empty'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                    return false;
                }
                this.memberData = res.List[0];
                this.$forceUpdate()
            })

        },
        checkMemberData() {
            return !util.isEmpty(this.memberData)
        },
        returnMemberImg() {
            if (!util.isEmpty(this.memberData.Files) && !util.isEmpty(this.memberData.Files.Profile)) {
                return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(62, 62, 'png')}`
            }
        },
        openImageFile(poster) {
            let url = ''
            if (!util.isEmpty(poster)) {
                url = poster[0].org_url
            }
            window.open(url, '_blank');
        },
        checkJudges(data) {
            if (!util.isEmpty(data)) {
                if (data.c_idx === 32) {
                    return false;
                }
                let judgesIndex = data.Judges.findIndex(judges => judges.mb_no === this.UserInfo.mb_no);
                if (judgesIndex > -1) {
                    return false;
                }
                if (data.c_status === 1) {
                    return true;
                }
                return false;
            }
        },
        openShareModal(data) {
            this.$modal.show('share-modal', {competitionData: data});
        },
        awsS3Download(type, name, key, nft) {
            this.runDownloadFileInfo(type, name, key, nft).then(res => {
                if (!res) {
                    this.errorAlert(this.$t('file_download_error'));
                }

            })
        },
    },
    watch: {
        "$route.fullPath": {
            deep: true,
            handler: function (val, oldVal) {
                if (!this.$route.query.tab) {
                    this.changeTab('item');
                } else if (this.tab != this.$route.query.tab) {
                    this.changeTab(this.$route.query.tab);
                }
            }
        },
        "competitionData": {
            deep: true,
            handler: function (val, oldVal) {
                this.setMetaTitle();
            }
        },
    },
}
</script>

<style scoped>

</style>
