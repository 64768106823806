import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
export default {
    mixins: [alertMixins],
    data() {
        return {
            fileToken: ""
        }
    },
    methods: {
        getFileJwtToken(type, id) {
            EventBus.$emit('apiLoading', true)
            let params = {
                type: type,
                key: id,
            }
            try {
                return this.$api.$file.download(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type !== 1) {
                        this.errorAlert(this.$t('fail_filedownload'));
                        EventBus.$emit('apiLoading', false)
                        return
                    }
                    this.fileToken = res.List.token;
                    this.runDecode(this.fileToken);
                })
            }catch (e) {
                console.log(e)
            }
        },
        runDecode(data) {

            let dataURL2 = data
            let decodeObj = this.$jwt.decode(dataURL2, this.$jwt.options.signKey)
            if(util.isEmpty(decodeObj) || util.isEmpty(decodeObj.base64) || util.isEmpty(decodeObj.a_filename)) {
                this.errorAlert(this.$t('fail_filedownload'))
                EventBus.$emit('apiLoading', false)
            }else{
                this.fileDown(decodeObj.base64, decodeObj.a_filename)
            }
        },
        fileDown(dataURL, dataName = 'test.png') {
            let base64toBlob = function (base64Data, contentType) {
                contentType = contentType || '';
                var sliceSize = 1024;
                var byteCharacters = atob(base64Data);
                var bytesLength = byteCharacters.length;
                var slicesCount = Math.ceil(bytesLength / sliceSize);
                var byteArrays = new Array(slicesCount);

                for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                    var begin = sliceIndex * sliceSize;
                    var end = Math.min(begin + sliceSize, bytesLength);

                    var bytes = new Array(end - begin);
                    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                        bytes[i] = byteCharacters[offset].charCodeAt(0);
                    }
                    byteArrays[sliceIndex] = new Uint8Array(bytes);
                }
                return new Blob(byteArrays, {type: contentType});
            }
            let blobFile = base64toBlob(dataURL)
            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(blobFile, {type: blobFile.type});
            a.download = dataName
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            EventBus.$emit('apiLoading', false)
        },

        download(data) {
            try {
                function getBase64Image(img) {
                    var canvas = document.createElement("canvas");
                    canvas.width = img.width;
                    canvas.height = img.height;
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0);
                    var dataURL = canvas.toDataURL("image/png");
                    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
                }

                if (!window.ActiveXObject) {
                    const a = document.createElement("a");
                    a.style.display = "none";
                    document.body.appendChild(a)
                    a.href = data.org_url;
                    let name = data.org_name;
                    a.setAttribute("download", name);
                    a.setAttribute("target", "_blank");
                    a.click();
                    window.URL.revokeObjectURL(a.href);
                    document.body.removeChild(a);
                    /*fetch(data.org_url, {methods: 'GET', credentials: 'omit'}).then(r => r.blob()).then(blob => {
                        const a = document.createElement("a");
                        a.style.display = "none";
                        document.body.appendChild(a)
                        a.href = window.URL.createObjectURL(blob);
                        let name = data.org_name;
                        a.setAttribute("download", name);
                        a.click();
                        window.URL.revokeObjectURL(a.href);
                        document.body.removeChild(a);
                    });*/
                }

                else if (window.ActiveXObject && document.execCommand) {
                    var _window = window.open(data.org_url, '_blank');
                    let name = data.org_name;
                    _window.document.close();
                    _window.document.execCommand('SaveAs', true, name || this.src)
                    _window.close();
                }
            } catch (e) {
                console.log(e)
            }
        },
    }
}
